import React, {memo} from "react";
// react-bootstrap components
import {
  Card,
  Table,
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { FaMinusCircle, FaPlusCircle, FaShare, FaShareAlt, FaThumbsUp } from 'react-icons/fa';

function Addfavcolumn(props) {
    var favorites = props.favorites || {}
    var game = props.game || {}
    var player = props.player || {}
    var addFavorite = props.addFavorite || function(){}

    if(player.rosterStatus != "Active"){
        return
    }
    return(
      <div>
        {(!favorites[game.gameId]?.teams[player.teamId] || favorites[game.gameId]?.teams[player.teamId]?.players[player.personId]?.type == 'team')?
        <Button className="btn btn-xs" onClick={(e)=>{addFavorite({gameId: game.gameId, type: "team", teamId: player.teamId.toString(), playerId: player.personId.toString()})}}>
          {(!favorites[game.gameId]?.teams[player.teamId]?.players[player.personId])?<FaPlusCircle />:<FaMinusCircle />}
        </Button>:""}
      </div>
    )
  }


  function DkOdds(props) {
    var clock = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }))
    return (
      <div>
        {(props.player?.odds && clock.getHours()>=12)?"DK: "+props.player.odds:""}
      </div>
    );
  }

  function Livecolumn(props) {
    const live = props.live 
    const game = props.game
    const player = props.player
    const liveGame = live[game.gameId]
    if(!liveGame){
        return;
    }
    const livePerson = liveGame.players[player.personId] || {onCourt:false, live:{}}
    const liveFirst = live[game.gameId].first
    
    return(
      <div>
        {(livePerson?.live?.threesMade)} <span>/</span> {(livePerson?.live?.threesAttempted)}
        {(livePerson.onCourt)?<span style={{display:"block"}}>⎄🏀</span>:""}
        {(liveFirst.made && liveFirst.made.personId == player?.personId)?
          <span style={{display:"block"}}>🏆&nbsp;3PM&nbsp;G</span>:
          <span>{(liveFirst.teamMade && liveFirst.teamMade[player.teamId]?.personId == player.personId)?<span style={{display:"block"}}>🏆&nbsp;3PM&nbsp;T</span>:""}</span>}
        {(liveFirst.attempt && live[game.gameId].first.attempt.personId == player.personId)?
          <span style={{display:"block"}}>✓&nbsp;3PA&nbsp;G</span>:
          <span>{(liveFirst.teamAttempt && liveFirst.teamAttempt[player.teamId]?.personId == player.personId)?<span style={{display:"block"}}>✓&nbsp;3PA&nbsp;T</span>:""}</span>}
      </div>
    )
  }

  const PlayerList = memo((props) => {

    var favorites = props.favorites || {}
    var game = props.game || {}
    var live = props.live || {}
    var player = props.player || {}
    var picks = props.picks || {}
    var proEnabled = props.proenabled || false
    var addFavorite = props.addFavorite || function(){}
    var id = props.id || 1

    var removeDiacritics = (str)=> {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      }

    var playerImg = (playerName) =>{
        try {
            //console.log('player img')
            var parsedName = removeDiacritics(playerName).replaceAll(" ","-")
            return require(`assets/img/player_images/${parsedName}.jpg`);
          } catch (error) {
            return require(`assets/img/player_images/player.png`);
          }
    }
    return(
      <tr key={id+player.personId} className={(picks[player.personId])?"highlight":""} >
        <td className="text-white" style={{width:"35%", paddingRight:10}}>
            <div className="player-container">
            <img src={playerImg(player.playerName)} className="player-img" />
            <span className="player-number">#{player.jerseyNumber}</span><span className="player-name">{player.playerName}</span>
            
            {(player.position)?(player.lineupStatus == "Confirmed")?(player.starting)?<span className="badge bg-primary">Starting {player.position}</span>:<span className="badge">Bench</span>:<span className="badge border-secondary">Lineup Pending</span>:<></>}
            {(false && picks[player.personId])?<span className="badge bg-secondary">Favorite</span>:null}
            {(player.rosterStatus != "Active")?"Inactive ":
            (player.position)?(player.lineupStatus == "Confirmed")?null:<></>:<>Bench</>}
            
            {(proEnabled)?
            <div className={(player.health_score != 5)?"injury":null}>
                {(player.health_score != 5)?<span >Health:&nbsp;{player.health_score}/5</span>:null}
                {(player.injury)?<span> - {player.injury}</span>:<></>}
            </div>
            :<></>}
          </div>
        </td>
        {(player.threes)?
        <>
        
        {(false)?
        <td className="text-white" style={{display:"none"}}>
          <b>
            {Object.values(player.threes.firstThree).length}/{Object.values(player.threes.firstThreeAttempt).length}<br></br>
            {(Object.values(player.threes.firstThree).length>0)?(Object.values(player.threes.firstThree).reduce((a, b) => a + b) / Object.values(player.threes.firstThree).length).toFixed(0):"-"}/
            {(Object.values(player.threes.firstThreeAttempt).length>0)?(Object.values(player.threes.firstThreeAttempt).reduce((a, b) => a + b) / Object.values(player.threes.firstThreeAttempt).length).toFixed(0):"-"}
          </b>
        </td>:null}
        <td className="text-white">
          <b>
            {Object.values(player.threes.firstTeamThree).length}/{Object.values(player.threes.firstThreeTeamAttempt).length}<br></br>
            {(Object.values(player.threes.firstTeamThree).length>0)?(Object.values(player.threes.firstTeamThree).reduce((a, b) => a + b) / Object.values(player.threes.firstTeamThree).length).toFixed(0):"-"}/
            {(Object.values(player.threes.firstThreeTeamAttempt).length>0)?(Object.values(player.threes.firstThreeTeamAttempt).reduce((a, b) => a + b) / Object.values(player.threes.firstThreeTeamAttempt).length).toFixed(0):"-"}
          </b>
        </td>
        <td className="text-white">
          {(player.totalThreeMade/Object.values(player.threes.attempts).length).toFixed(2)}<br />
          {(Object.values(player.threes.attempts).length)}<br />
          {player.starts}
        </td>
        {/*
        <td className="text-white">
          {(Object.values(player.threes.made).length>0)?(Object.values(player.threes.made).reduce((a, b) => a + b) / Object.values(player.threes.made).length).toFixed(0):"-"}/
          {(Object.values(player.threes.attempts).length>0)?(Object.values(player.threes.attempts).reduce((a, b) => a + b) / Object.values(player.threes.attempts).length).toFixed(0):"-"}
        </td>*/}
        <td className="text-white">{player.totalThreeMade}/{player.totalThreeAttempts}<br/>{((player.totalThreeMade/player.totalThreeAttempts*100) || 0).toFixed(2)}%</td>
        <td>
          <DkOdds player={player}/>
          TD:&nbsp;
          {
            (Object.values(player.threes.firstTeamThree).length/player.starts
            *
            ((player.totalThreeMade/player.totalThreeAttempts*100) || 0)
            *
            ((1000
            -
            ((Object.values(player.threes.firstThreeTeamAttempt).length>0)?(Object.values(player.threes.firstThreeTeamAttempt).reduce((a, b) => a + b) / Object.values(player.threes.firstThreeTeamAttempt).length).toFixed(0):"0")
            )/1000)).toFixed(2)
            
          }
        </td>
        <td>
          
          {(live[game.gameId] && live[game.gameId].lastPlay)?(
            <Livecolumn player={player} live={live} game={game} />
            ):(
            <Addfavcolumn favorites={favorites} game={game} player={player} addFavorite={addFavorite}/>
            )
          }
          
        </td>
        </>:<td colSpan="1000">No Data</td>}
    </tr>
    )
  })

  export default PlayerList;