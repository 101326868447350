import React, { useState, useEffect } from 'react';

const NumberFlip = ({ number }) => {
  const [prevNumber, setPrevNumber] = useState(number);
  const [flipped, setFlipped] = useState(false);

  useEffect(() => {
    if (number !== prevNumber) {
      setFlipped(true);
      setTimeout(() => {
        setPrevNumber(number);
        setFlipped(false);
      }, 500); // Adjust to match the transition duration
    }
  }, [number, prevNumber]);

  return (
    <div className={`flip-container ${flipped ? 'flipped' : ''}`}>
      <div className="flip-card">
        <div className="flip-front">{prevNumber}</div>
        <div className="flip-back">{number}</div>
      </div>
    </div>
  );
};

export default NumberFlip;