import React, { useRef, useState, useEffect } from "react";
import "assets/css/calendar.css";
import ChartistGraph from "react-chartist";


const NbaCalendarView = () => {
  const [profit, setProfit] = useState([]);
  const [profitKeys, setProfitKeys] = useState([]);
  const [endingBalance, setEndingBalance] = useState([]);

  const queryParameters = new URLSearchParams(window.location.search)
  const devDomain = "http://localhost:3001"; 
  const prodDomain = "https://api.threes.day";
  const devWebDomain = "http://localhost:3000";
  const prodWebDomain = "https://threes.day";
  const env = queryParameters.get("env")

  const domain = (!env || env == 'prod')?prodDomain:devDomain;
  const webDomain = (!env || env == 'prod')?prodWebDomain:devWebDomain;

  function getYesterdaysRecap() {
    fetch(domain+'/pick/year/record')
    .then(results => results.json())
    .then(data => {
      const {byDate} = data["2024"];
      var profitL = [0]
      var endingAccountBalanceL = Object.values(byDate).map((day)=>day.endingAccountBalance)
      endingAccountBalanceL.forEach((day,idx)=>{
        if(idx>0){
          profitL.push(profitL[idx-1]+day)
        }
      })
      var graphDates = Object.keys(byDate)
      console.log('pl', profitL, graphDates, endingAccountBalanceL)
      setProfit(profitL)
      setEndingBalance(endingAccountBalanceL)
      setProfitKeys(graphDates)
    })
  }

  useEffect(() => {
    // Fetch data from the NBA schedule JSON
    getYesterdaysRecap()
  }, []);

  return (<>
    <div style={{ textAlign:"center", width:"100vw"}}>
      <span style={{background:"black", color:"white", display: "block",verticalAlign: "middle"}}>Profit Tracker</span>
      {(profit && profitKeys)?<ChartistGraph className="ct-chart" style={{fontSize:10, background:"white", margin:"0 auto", paddingTop:20}}
                    data={{
                      labels: profitKeys,
                      series: [
                        endingBalance
                      ],
                    }}
                    type="Line"
                    options={{
                      low: -150,
                      high: 1500,
                      showArea: false,
                      height: "245px",
                      axisX: {
                        showGrid: false,
                      },
                      scale: {
                        pointLabels: {
                          fontSize: 10,
                      },
                    },
                      lineSmooth: true,
                      showLine: true,
                      showPoint: true,
                      fullWidth: true,
                      chartPadding: {
                        right: 100,
                        top:50
                      },
                      labelOffset: 50,
                      labelDirection: 'explode'
                    }}
                  />:null}
                  <span style={{display:"block", background:"black", color:"white"}}>This is the ending balance of your account if you placed a $100 bet on every AI pick we make.</span>
                  <span style={{fontSize:"30px"}}>More data coming soon.</span>

    </div>
    </>
  );
};

export default NbaCalendarView;
