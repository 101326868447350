import bbcourt from "assets/img/bbcourt.png"

export default class courtSimulator{

    constructor(canvas){
        this.canvas = canvas
        this.context =this.canvas.getContext('2d');

        this.inView = this.canvas.getAttribute('inview')
        this.fps = 100;
        this.showGrid = false;

        this.isPro = this.canvas.getAttribute('ispro')

        this.awayImageSrc =this.canvas.getAttribute('awayimage')
        this.homeImageSrc =this.canvas.getAttribute('homeimage')

        this.pixelRatio = window.devicePixelRatio;
        this.sizeOnScreen =this.canvas.getBoundingClientRect();
    
       this.canvas.width = this.sizeOnScreen.width * this.pixelRatio;
       this.canvas.height = this.sizeOnScreen.height * this.pixelRatio;
    
        // Shrink back down thethis.canvas CSS size by the pixel ratio, thereby 'compressing' the pixels.
       this.canvas.style.width = (this.canvas.width / this.pixelRatio) + 'px';
       this.canvas.style.height = (this.canvas.height / this.pixelRatio) + 'px';
    
        this.canvasWidth =this.canvas.width;
        this.canvasHeight =this.canvas.height;
    
        this.background = new Image();
        this.background.src = bbcourt;
        this.awayTeamImg = new Image();
        this.awayTeamImg.src = this.awayImageSrc;
        this.homeTeamImg = new Image();
        this.homeTeamImg.src = this.homeImageSrc;
        this.logoSize = 50

        this.dotMeta = {
        x: 25*this.pixelRatio,
        y: 25*this.pixelRatio,
        radius: 5*this.pixelRatio,
        xMove: "+", // xMove and yMove tell us which direction to move the dot
        yMove: "+",
        accelX: [],
        accelY: [],
        distanceX: 0,
        distanceY: 0,
        ball: false,
      };
       
      this.dots = [
        this.cp(this.dotMeta), 
        this.cp(this.dotMeta), 
        this.cp(this.dotMeta), 
        this.cp(this.dotMeta), 
        this.cp(this.dotMeta), 
        this.cp(this.dotMeta), 
        this.cp(this.dotMeta), 
        this.cp(this.dotMeta), 
        this.cp(this.dotMeta), 
        this.cp(this.dotMeta)
    ]
  
       this.boundry = 15 * this.pixelRatio
       this.workableCourt = {w:this.context.canvas.width-2*this.boundry, h:this.context.canvas.height-2*this.boundry}
       this.fullCourt = {x: this.boundry, y: this.boundry, w: this.workableCourt.w, h: this.workableCourt.h}
       this.leftCourt = {x: this.boundry, y: this.boundry, w: this.context.canvas.width/2 - this.boundry, h: this.workableCourt.h}
       this.rightCourt = {x: this.context.canvas.width/2, y: this.boundry, w: this.context.canvas.width/2-this.boundry, h: this.workableCourt.h}
       this.enterCourt = {x: this.boundry + this.workableCourt.w/4, y:this.boundry + (this.workableCourt.h)/4, w: this.context.canvas.width/4, h: this.workableCourt.h}
       this.awayBench   = {x: this.boundry + this.workableCourt.w/6, y: this.context.canvas.height-(2*this.boundry), w: this.context.canvas.width/4, h: 2*this.boundry}
       this.homeBench   = {x: this.boundry + this.workableCourt.w/2+this.workableCourt.w/6, y: this.context.canvas.height-(2*this.boundry), w: this.context.canvas.width/4, h: 2*this.boundry}
  
       this.quadrants = {w:24, h:0}
       this.quadrantSize = this.workableCourt.w/this.quadrants.w
       this.quadrants.h = parseInt(this.workableCourt.h/this.quadrantSize)

      this.positions = {
        'all':{'home':this.fullCourt, 'away':this.fullCourt}, 
        'split':{'home':this.rightCourt, 'away':this.leftCourt},
        'left':{'home':this.leftCourt, 'away':this.leftCourt, 'quadrants':{"home":[[1,1], [6,2], [5,7], [2,8], [2,5]], "away":[[1,2], [5,3], [4,7], [1,7], [1,5]]}},//[2,5] if 
        'center':{'home':this.centerCourt, 'away':this.centerCourt},
        'right':{'home':this.rightCourt, 'away':this.rightCourt, 'quadrants':{"away":[[22,1], [17,2], [18,7], [21,8], [21,5]], "home":[[22,3], [18,4], [19,7], [21,7], [22,5]]}},
        'bench':{'home':this.homeBench, 'away':this.awayBench},
        'homefreethrow':{'home':this.leftCourt, 'away':this.leftCourt, 'quadrants':{'home':[[2,2], [4,2], [5,4], [2,6], [10,6]], 'away':[[1,2], [3,2], [3,6], [1,6], [9,7]]}},
        'awayfreethrow':{'home':this.rightCourt, 'away':this.rightCourt, 'quadrants':{'home':[[22,2], [19,2], [20,2], [22,6], [13,6]], 'away':[[21,2], [19,2], [18,4], [21,6], [13,7]]}},
      } // move away quadrants to the right of court

          /*
            0,0                       23,0
                    10,3      13,3       
                        11,5 12,5
                    10,7      13,7
            0,10                      23,10
            */

        window.requestAnimationFrame(this.animation);
    }



    animation = () => {
        this.inView = this.canvas.getAttribute('inview')
        if(this.inView == "false" || !this.inView){
            setTimeout(()=>{
                this.animation();
            },500);
            return;
        } else {
            setTimeout(() => {
                window.requestAnimationFrame(this.animation);
              }, 1000 / this.fps);
        }


        this.isPro = this.canvas.getAttribute('ispro')
        var overlayText = ""
        var proBlocker = false
        var lastPlay =this.canvas.getAttribute('lastplay') || ""
        var position =this.canvas.getAttribute('position')
        var homePlayers =this.canvas.getAttribute('homeplayers')?.split(",") || new Array(5)
        var awayPlayers =this.canvas.getAttribute('awayplayers')?.split(",") || new Array(5)
        this.context.clearRect(0, 0,this.canvasWidth,this.canvasHeight);
        this.context.setTransform(1, 0, 0, 1, 0, 0);
        this.context.drawImage(this.background,0,0, this.context.canvas.width, this.context.canvas.height);  
        this.context.drawImage(this.awayTeamImg, this.context.canvas.height-(this.boundry)-(this.logoSize*this.pixelRatio), this.boundry*1.5, this.logoSize*this.pixelRatio, this.logoSize*this.pixelRatio)
        this.context.drawImage(this.homeTeamImg, this.context.canvas.height+this.boundry, this.context.canvas.height-(1.5*this.boundry)-(this.logoSize*this.pixelRatio), this.logoSize*this.pixelRatio, this.logoSize*this.pixelRatio)
        this.context.save()
        
        if(lastPlay && (!this.isPro || this.isPro == "false")){
            proBlocker = true
            overlayText = "Upgrade to Pro for Live Game"
        }
        if(!lastPlay){
            overlayText = "Pre Game"
        }
        if(!proBlocker){
            var lpParser = lastPlay.toLowerCase();
            var playerAction = -1
            // figure out who has player action from last play
            homePlayers.forEach((player,idx)=>{
            if(!player){
                return
            }
            if(this.dots[idx]){
                this.dots[idx].ball = false
                var playerLastName = player.split(" ")[1].toLowerCase()
                if(lpParser.indexOf(playerLastName)>=0){
                    playerAction = idx
                    this.dots[idx].ball = true
                }
            }
            })
        
            awayPlayers.forEach((player,idx)=>{
            if(!player){
                return
            }
            this.dots[idx].ball = false
            var playerLastName = player.split(" ")[1].toLowerCase()
            if(lpParser.indexOf(playerLastName)>=0){
                playerAction = idx+5
                this.dots[idx+5].ball = true
            }
            })
            if(playerAction>5){
                position = 'right'
            } else if(playerAction>=0){
                position = 'left'
            } else {
                position = 'split'
            }

            if(lpParser.indexOf('timeout') >= 0 || lpParser.indexOf('end') >= 0 || lpParser.indexOf('half') >= 0){
            position = 'bench'
            }
            if((lpParser.indexOf('free throw') >= 0 || lpParser.indexOf('freethrow') >= 0) && this.positions[position]?.quadrants){
            // set to free throw 
            if(playerAction>=5){
                //away free throw
                position = 'awayfreethrow'
                var freeThrowLine = [18,4]
                //move quadrant for playerAction to shoot
                if(this.positions[position].quadrants.away[playerAction%5] && typeof this.positions[position].quadrants.away[playerAction%5] == "Array" && !this.positions[position].quadrants.away[playerAction%5].equals(freeThrowLine)){
                awayPlayers.forEach((player,idx)=>{
                    if(this.positions[position].quadrants.away[idx]?.equals(freeThrowLine)){
                        this.positions[position].quadrants.away[idx] = this.positions[position].quadrants.away[playerAction%5] //move this person
                    }
                })
                this.positions[position].quadrants.away[playerAction%5] = freeThrowLine // move them to the line
                }
            } else {
                //home free throw
                position = 'homefreethrow'
                var freeThrowLine = [5,4]
                if(this.positions[position].quadrants.home[playerAction%5] && typeof this.positions[position].quadrants.home[playerAction%5] == "Array" && !this.positions[position].quadrants.home[playerAction%5].equals(freeThrowLine)){
                homePlayers.forEach((player,idx)=>{
                    if(this.positions[position].quadrants.home[idx]?.equals(freeThrowLine)){
                        this.positions[position].quadrants.home[idx] = this.positions[position].quadrants.home[playerAction] //move this person
                    }
                })
                this.positions[position].quadrants.home[playerAction] = freeThrowLine // move them to the line
                }
            }
            }
            this.context.font = "30px Arial";
            this.context.fillStyle = "black";
            //context.moveTo(0,boundry)
            this.context.fillText(lastPlay, 200*this.pixelRatio,this.boundry/2);

            if(this.showGrid){
                this.context.globalAlpha = 1
                this.context.font = "24px Arial";
                this.context.textAlign="center"; 
                this.context.textBaseline = "middle";
                this.context.fillStyle = "black";
            for(this.q = 0; q<this.quadrants.w; q++){
                for(this.qq = 0; qq<this.quadrants.h; qq++){
                    this.context.beginPath(); // Start a new path
                    this.context.moveTo(this.boundry+this.quadrantSize*q, this.boundry+this.quadrantSize*qq); // Move the pen to (30, 50)
                    this.context.lineTo(this.boundry+this.quadrantSize*(q+1), this.boundry+this.quadrantSize*qq); // Draw a line to (150, 100)
                    this.context.stroke(); // Render the path
                    this.context.moveTo(this.boundry+this.quadrantSize*q, this.boundry+this.quadrantSize*qq); // Move the pen to (30, 50)
                    this.context.lineTo(this.boundry+this.quadrantSize*q, this.boundry+this.quadrantSize*(qq+1)); // Draw a line to (150, 100)
                    this.context.stroke(); // Render the path
                    this.context.fillText(q +","+ qq,this.boundry+this.quadrantSize*q+15, this.boundry+this.quadrantSize*qq+15);
                }
            }
            }
            this.context.globalAlpha = 0.85


            //context.translate(logoSize*pixelRatio,0)
            //context.rotate((90 * Math.PI) / 180);


            //context.setTransform(1, 0, 0, 1, 0, 0);
            this.context.globalAlpha = 1
            homePlayers.forEach((player, idx)=>{
                this.moveDot(this.dots[idx], player, idx, "#F03C69", 'home', position)
            })
            awayPlayers.forEach((player, idx)=>{
                this.moveDot(this.dots[idx+5], player, idx, "#1B7598", 'away', position)
            })
        }
        if(overlayText){
            this.context.restore()
            this.context.save()
            // text overlay block
            this.context.font = "80px Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji";
            this.context.fillStyle = 'rgba(0,0,0,0.5)';
            /// get width of text
            var width =  this.context.measureText(overlayText).width;
            var height = parseInt(this.context.font, 10)
            /// draw background rect assuming height of font
            this.context.fillRect(200*this.pixelRatio-(width/2)-20,this.context.canvas.height/2-(height/2)-20, width+40, height+40);
            this.context.fillStyle = "white";
            this.context.fillText(overlayText, 200*this.pixelRatio-(width/2),this.context.canvas.height/2+20);
            this.context.restore()
        }

    }

    moveDot(dot, name, idx, color, team, position){
        // Find the x and y values of the dot based on the direction it is to move.
        //this.dot = cp(tmp)
        var speed = idx%5
        var speedBase = 1//(position=='bench')?0.25:1
        //if(typeof dot.accelX != 'Array'){
        //dot.accel = []
        //}
        if(!dot){
        return
        }
        var accelCalcX = 0
        var accelCalcY = 0
        if(dot.distanceX<dot.accelX.length){
        accelCalcX = dot.accelX[dot.distanceX]
        } else {
        dot.accelX = []
        }
        if(dot.distanceY<dot.accelY.length){
        accelCalcY = dot.accelY[dot.distanceY]
        } else {
        dot.accelY = []
        }
        var moveX = speedBase+accelCalcX
        var moveY = speedBase+accelCalcY
        if (dot.xMove == "+") {
        dot.x += moveX;
        dot.distanceX += 1
        } else {
        dot.x -= moveX;
        dot.distanceX += 1
        }
        if (dot.yMove == "+") {
        dot.y += moveY;
        dot.distanceY += 1
        } else {
        dot.y -= moveY;
        dot.distanceY += 1
        }

        // Draw the dot in its new position.
        // accel //+ "("+moveX.toFixed(3)+")"
        this.drawDot(dot, name , color);

        // If we hit a boundary in some direction, we reverse the movement in the
        // direction that caused the collision.
        var pp = this.positions[position]
        var dotRight = dot.x + dot.radius
        var dotLeft = dot.x - dot.radius
        var dotBottom = dot.y - dot.radius 
        var dotTop = dot.y + dot.radius 
        /*
        //full court mode bouncy
        var boundsRight = pp[team].x+pp[team].w
        var boundsLeft = pp[team].x
        var boundsTop = pp[team].y+pp[team].h
        var boundsBottom = pp[team].y
        */

        //grid mode
        var qq = (pp.quadrants && pp.quadrants[team])?pp.quadrants[team]:false
        var boundsRight = pp[team].x+pp[team].w
        var boundsLeft = pp[team].x
        var boundsTop = pp[team].y+pp[team].h
        var boundsBottom = pp[team].y
        
        if(qq && qq[idx%5]){
        var quadX = qq[idx%5][0]
        var quadY = qq[idx%5][1]
        boundsRight = this.boundry+this.quadrantSize*(quadX+1)
        boundsLeft = this.boundry+this.quadrantSize*quadX
        boundsTop = this.boundry+this.quadrantSize*quadY
        boundsBottom = this.boundry+this.quadrantSize*(quadY+1)
        }
        

        if(dotRight <= boundsRight && dotLeft >= boundsLeft ){
        //its in its quadrent slow it down
        dot.accelX=[-.99, -.99]
        dot.distanceX = 0
        if(idx == 1 && qq && team=='home' && position=='left') {
            qq[idx][0] = (qq[idx][0]==9)?6:9
            qq[idx][1] = (qq[idx][1]==5)?2:5
        }
        if(idx == 1 && qq && team=='away' && position=='left') {
            qq[idx][0] = (qq[idx][0]==8)?5:8
            qq[idx][1] = (qq[idx][1]==4)?2:4
        }
        if(idx == 1 && qq && team=='home' && position=='right') {
            qq[idx][0] = (qq[idx][0]==23)?18:(qq[idx][0]==16)?23:16
            qq[idx][1] = (qq[idx][1]==4)?2:4
        }
        if(idx == 1 && qq && team=='away' && position=='right') {
            qq[idx][0] = (qq[idx][0]==21)?17:21
            qq[idx][1] = (qq[idx][1]==4)?3:4
        }
        }
        if(dotBottom >= boundsBottom && dotTop <= boundsTop){
        dot.accelY=[-.90, -.90]
        dot.distanceY = 0
        }

        var accelRate = 0.01
        if ( dotRight >= boundsRight) { //go left
        if(dot.accelX.length == 0 && dotRight - 25*this.pixelRatio > boundsRight) { // if the dot is more that 50px outside of right bounds accel them left
            var accelCurveDistance = dotRight - boundsRight
            var accel = []
            for(var i=0; i<=accelCurveDistance/(this.pixelRatio*2); i++){
            accel.push(i*accelRate)
            }
            var accelR = JSON.parse(JSON.stringify(accel)).reverse()
            dot.accelX = [...accel, ...accelR]
            //console.log('d', dot.accel, accelR)
            dot.distanceX = 0
        }
        dot.xMove = "-";
        }
        if (dotLeft <= boundsLeft) {
        if(dot.accelX?.length == 0 && dotLeft -25*this.pixelRatio< boundsLeft) { // if the dot is more that 50px outside of right bounds accel them left
            //console.log("accel right", dotLeft -25*pixelRatio, boundsLeft)
            var accelCurveDistance = boundsLeft-dotLeft -25*this.pixelRatio
            var accel = []
            for(var i=0; i<=accelCurveDistance/(this.pixelRatio*2); i++){
            accel.push(i*accelRate)
            }
            var accelR = JSON.parse(JSON.stringify(accel)).reverse()
            dot.accelX = [...accel, ...accelR]
            //console.log('set dot accel right', dot.accel)
            dot.distanceX = 0
        }
        dot.xMove = "+";
        }
        if (dotTop>= boundsTop) {
        dot.yMove = "-";
        }
        if (dotBottom <= boundsBottom) {
        dot.yMove = "+";
        }
        return dot
    }

    drawDot(dot, word, color = "#F03C69") {
        
        this.context.beginPath();
        this.context.arc(dot.x, dot.y, dot.radius, 0, 2 * Math.PI, false);
        this.context.fillStyle = color;
        this.context.fill();
        //this.context.fillStyle = "#888";
        //this.context.fillRect(dot.x-75, dot.y+20, 150, 50);
        var wordSize = ((word.length * 3)<20)?20:(word.length * 4)>30?word.length * 3:word.length*3.5
        var rectHeight =15*this.pixelRatio;
        var rectWidth = 2*wordSize*this.pixelRatio;
        var rectX = dot.x-wordSize*this.pixelRatio;
        var rectY = dot.y+10*this.pixelRatio;
        this.roundRect(this.context,rectX, rectY, rectWidth, rectHeight, 5, true);
        this.context.font = "30px Arial";

        this.context.textAlign="center"; 
        this.context.textBaseline = "middle";
        this.context.fillStyle = "white";
        this.context.fillText(word,rectX+(rectWidth/2),rectY+(rectHeight/2));
        
    }

    roundRect(ctx, x, y, width, height, radius, fill, stroke) {
        if (typeof stroke == "undefined" ) {
        stroke = true;
        }
        if (typeof radius === "undefined") {
        radius = 5;
        }
        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.lineTo(x + width - radius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        ctx.lineTo(x + width, y + height - radius);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        ctx.lineTo(x + radius, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        ctx.lineTo(x, y + radius);
        ctx.quadraticCurveTo(x, y, x + radius, y);
        ctx.closePath();
        if (stroke) {
        ctx.stroke();
        }
        if (fill) {
        ctx.fill();
        }        
    }

    rnd(max) {
        return Math.floor(Math.random() * max);
      }
    cp(obj){
        obj.x = (25+this.rnd(250))*this.pixelRatio
        obj.y = (25+this.rnd(150))*this.pixelRatio
        obj.xMove = (this.rnd(4)>1)?"+":"-"
        obj.yMove = (this.rnd(4)>1)?"+":"-"
        return JSON.parse(JSON.stringify(obj))
    }

}