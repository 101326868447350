/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import TableList from "views/TableList.js";
import LiveList from "views/LiveList.jsx";
import Skydrop from 'views/Skydrop.jsx'
import Home from 'views/Home.js'
import NbaCalendarView from "views/Calendar.jsx"
import DecryptorComponent from "views/Decode.jsx"
import ContactForm from "views/Contact";
import deleteAccount from "views/Delete";
import policy from "views/Policy"

const dashboardRoutes = [
  {
    path: "/live",
    name: "Live",
    icon: "nc-icon nc-chart-pie-35",
    component: LiveList,
    layout: "/admin"
  },
  {
    path: "/home",
    name: "Home",
    icon: "nc-icon nc-chart-pie-35",
    component: Home,
    layout: "/admin"
  },
  {
    path: "/policy",
    name: "Policy",
    icon: "nc-icon nc-chart-pie-35",
    component: policy,
    layout: "/b"
  },
  {
    path: "/delete",
    name: "Delete",
    icon: "nc-icon nc-chart-pie-35",
    component: deleteAccount,
    layout: "/b"
  },
  {
    path: "/skydrop",
    name: "Skydrop",
    icon: "nc-icon nc-chart-pie-35",
    component: Skydrop,
    layout: "/b"
  },
  {
    path: "/decode",
    name: "DecryptorComponent",
    icon: "nc-icon nc-chart-pie-35",
    component: DecryptorComponent,
    layout: "/b"
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: "nc-icon nc-chart-pie-35",
    component: NbaCalendarView,
    layout: "/b"
  },
  {
    path: "/dashboardz",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: TableList,
    layout: "/admin"
  }
  ,
  {
    path: "/contact",
    name: "Contact",
    icon: "nc-icon nc-chart-pie-35",
    component: ContactForm,
    layout: "/admin"
  }
 
  
];

export default dashboardRoutes;
