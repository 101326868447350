import React from "react";
import dkimg from "assets/img/dk.png"

export function DkLink(props){
    var games = props.games || {}
    var favorites = props.favorites || {}
    var proEnabled = props.proenabled || false;
    const [loadingDk, setLoadingDk] = React.useState(false);

    if(!games || !favorites) {
        return
    }
    var betLinks = ""
    var odds = 1
    games.map((game) => {
        if(favorites[game.gameId]){


            if(favorites[game.gameId].teams[game.homeTeam.teamId]){
                Object.values(game.homeTeam.players).map((player, id) => {
                    var favoriteHome = favorites[game.gameId].teams[game.homeTeam.teamId]?.players[player.personId]  
                    if (favoriteHome) {
                        odds *= (player.odds)?(parseInt(player.odds)/100+1):1
                        betLinks += (player.betId)?encodeURIComponent(player.betId) + "+":""
                    }
                })
            }
            if(favorites[game.gameId].teams[game.awayTeam.teamId]){
                Object.values(game.awayTeam.players).map((player, id) => {
                    var favoriteAway = favorites[game.gameId].teams[game.awayTeam.teamId]?.players[player.personId]
                    if (favoriteAway) {
                        odds *= (player.odds)?(parseInt(player.odds)/100+1):1
                        betLinks += (player.betId)?encodeURIComponent(player.betId) + "+":""
                    }
                })
            }
        }
    })
    odds = (odds)?parseInt(10*((odds*10)-10)):0
    if(!odds){
        return
    }

    var tapLink = async (url) => {
        setLoadingDk(true)
        if(!proEnabled){
            var date = new Date()
            var today = date.getDate().toString()+date.getMonth().toString()+date.getFullYear().toString()
            var trialStarted = await window.wvSDK.storage.get('trial')
            if (trialStarted == today || !trialStarted){
                window.wvSDK.confetti(50);
                alert("You have one free day left. Upgrade to PRO for a FREE month today.");
                launchDK(url);
                window.wvSDK.storage.set('trial', today)
                window.dataLayer.push({'event': 'dk-slip-free'})
            } else {
                window.wvSDK.paywall();
                window.dataLayer.push({'event': 'dk-slip-paywall'})
                setLoadingDk(false)
            }
        } else {
            window.dataLayer.push({'event': 'dk-slip-pro'})
            window.wvSDK.confetti(50);
            launchDK(url);
        }
        
    }

    var launchDK = (url) => {
        setTimeout(()=>{
            setLoadingDk(false)
            window.location.href = url;
        },3000)
    }

    return (
        <>
        <span style={{borderRadius: "2px", backgroundColor:"white", color: "green", textAlign:"center", minWidth:"calc(100vw - 40px)", padding:"10px", display: "block",maxWidth: "fit-content",marginLeft: "auto",marginRight: "auto"}}>
            Powered By: <img style={{width:45, marginTop:"-4px"}} src={dkimg}/>
            <span style={{display: "block",maxWidth: "fit-content",marginLeft: "auto",marginRight: "auto"}} >
                <button onClick={(event)=>{ tapLink('dksb://sb/addbet/' + betLinks); }} style={{padding:"7px", margin:"5px auto", border: "1px solid green", background:(loadingDk)?'#444':'green', fontSize:14, color:"white", borderRadius:5, float:"center", width:"calc(100vw - 60px)"}}>
                    <span style={{fontWeight:"bold", padding:"5px"}}>{(loadingDk)?'Loading...':'Add Bet +'+odds}</span>
                </button>
            </span>
            <small style={{fontSize:"5pt", lineHeight:"6px", display:"inline-block"}}>DRAFTKINGS® is a registered trademark of DK CROWN HOLDINGS INC. and not affilated with THREESDAY® or Hudson AI Platforms LLC</small>
        </span>
        <br />
        </>
    )
}