import React, {useRef} from "react";
import {useInterval} from '../tools/useInterval';
import json from "data.json"
// react-bootstrap components
import {
  Card,
  Table,
  Container,
  Row,
  Col,
  Button,
  Spinner,
} from "react-bootstrap";
import { FaMinusCircle, FaPlusCircle, FaShare, FaShareAlt, FaThumbsUp } from 'react-icons/fa';
import flyingPic from "assets/img/flying3.png";
import playerPic from "assets/img/player.png";
import { useHistory, useLocation } from 'react-router-dom'
import { jwtDecode } from "jwt-decode";
import PlayerList from "../components/PlayerList.jsx"
import { FavoriteCard } from "../components/FavoriteCard";
import { DkLink }       from "../components/DkLink";
import webviewSDK from "../tools/webviewSDK"
import courtSimulator from "components/CourtSimulator";
import NumberFlip from "components/NumberFlip";

var teams = Object.keys(json).map((key) => [key, json[key]]);

function LiveList() {

  const [games, setGames] = React.useState([]);
  const [live, setLive] = React.useState({})
  const [favorites, setFavorites] = React.useState({})
  const [favoritesToken, setFavoritesToken] = React.useState("")
  const [lastUpdated, setLastUpdated] = React.useState("")
  const [picksToken, setPicksToken] = React.useState("")
  const [picksFavorites, setPicksFavorites] = React.useState("")
  const [picksMatchFavorites, setPicksMatchFavorites] = React.useState(true)
  const [picks, setPicks] = React.useState({})
  const [yesterdaysResults, setYesterdaysResults] = React.useState("")
  const [yearResults, setYearResults] = React.useState("")
  const [clock, setClock] = React.useState(new Date())
  const [benchDisplay, setBenchDisplay] = React.useState({})
  const [awayBenchDisplay, setAwayBenchDisplay] = React.useState({})
  const [earliestGame, setEarliestGame] = React.useState()
  const [proEnabled, setProEnabled] = React.useState(false)
  const [user, setUser] = React.useState({})
  const [admin, setAdmin] = React.useState(false)


  const [loadingGames, setLoadingGames] = React.useState(false)
  const [canvasPlayerPosition, setCanvasPlayerPosition] = React.useState('split')
  const history = useHistory()
  const canvasRef = [useRef(null), useRef(null), useRef(null),useRef(null), useRef(null), useRef(null),useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
  const gamesRef = [useRef(null), useRef(null), useRef(null),useRef(null), useRef(null), useRef(null),useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

  

  const queryParameters = new URLSearchParams(window.location.search)
  const devDomain = "http://localhost:3001"; 
  const prodDomain = "https://api.threes.day";
  const devWebDomain = "http://localhost:3000";
  const prodWebDomain = "https://threes.day";
  const env = queryParameters.get("env")

  const domain = (!env || env == 'prod')?prodDomain:devDomain;
  const webDomain = (!env || env == 'prod')?prodWebDomain:devWebDomain;

  Array.prototype.equals = function (array) {
    // if the other array is a falsy value, return
    if (!array)
        return false;
    // if the argument is the same array, we can be sure the contents are same as well
    if(array === this)
        return true;
    // compare lengths - can save a lot of time 
    if (this.length != array.length)
        return false;

    for (var i = 0, l=this.length; i < l; i++) {
        // Check if we have nested arrays
        if (this[i] instanceof Array && array[i] instanceof Array) {
            // recurse into the nested arrays
            if (!this[i].equals(array[i]))
                return false;       
        }           
        else if (this[i] != array[i]) { 
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;   
        }           
    }       
    return true;
  }
  // Hide method from for-in loops
  Object.defineProperty(Array.prototype, "equals", {enumerable: false});


  function getGames() {
    setLoadingGames(true)
    return new Promise((complete)=>{
    fetch(domain+'/live?ts='+Date.now())
      .then(results => results.json())
      .then(data => {
        if(!data.scoreboard) { return }
        const {games} = data.scoreboard;
        const todaysGames = []
        Object.values(games).forEach((game)=>{
          if(game && ( (game.gameStatusText != "Final" || (new Date(game.gameEt).getDate()) == (new Date().getDate())) || (game.gameStatusText == "Final" && (new Date(game.gameEt).getDate()) != (new Date().getDate())) )  ){
            //its today

            const homeTeam = game.homeTeam.teamId;
            const awayTeam = game.awayTeam.teamId;
            game.awayTeam.starters = {}
            game.homeTeam.starters = {}
            
            if(!game.homeTeam.players) { // no game status yet, just default
              game.homeTeam.players = json[homeTeam].players;
              game.awayTeam.players = json[awayTeam].players;
            } else {
              Object.values(game.homeTeam.players).map((player, i)=>{
                if(json[homeTeam].players[player.personId]){
                  player.threes = json[homeTeam].players[player.personId].threes
                  player.totalThreeAttempts = json[homeTeam].players[player.personId].totalThreeAttempts
                  player.totalThreeMade = json[homeTeam].players[player.personId].totalThreeMade
                  player.starts = json[homeTeam].players[player.personId].starts
                }
                if(!player.threes) { player.threes = {"attempts":{}, "made":{}, "firstTeamThree":{},"firstThreeTeamAttempt":{},"firstThree":{},"firstThreeAttempt":{}} }
                if(player.starting) {
                  //starter
                  game.homeTeam.starters[player.personId] = player
                  //delete player
                }
              })
              Object.values(game.awayTeam.players).map((player, i)=>{
                if(json[awayTeam].players[player.personId]){
                  player.threes = json[awayTeam].players[player.personId].threes
                  player.totalThreeAttempts = json[awayTeam].players[player.personId].totalThreeAttempts
                  player.totalThreeMade = json[awayTeam].players[player.personId].totalThreeMade
                  player.starts = json[awayTeam].players[player.personId].starts
                }
                if(!player.threes) { player.threes = {"attempts":{}, "made":{}, "firstTeamThree":{},"firstThreeTeamAttempt":{},"firstThree":{},"firstThreeAttempt":{}} }
                if(player.starting) {
                  //starter
                  game.awayTeam.starters[player.personId] = player
                  //delete player
                }
              })
            }

            todaysGames.push(game)
          }
        })
        console.log('td',todaysGames);
        setGames(todaysGames);
        setLoadingGames(false)
        complete(todaysGames);
      });
    });
  }

  function getLive() {
    fetch(domain+'/live/pbp?ts='+Date.now())
    .then(results => results.json())
    .then(data => {
      const {games} = data;
      console.log(games)
      if(games && Object.keys(games).length>0){
        setLive(games)
        setLastUpdated(formaSecondsAMPM(new Date()))
      }
    })
  }

  function getJWT() {
    fetch(domain+'/pick/today/jwt')
    .then(results => results.json())
    .then(data => {
      const {access_token} = data;
      const {favorites} = jwtDecode(access_token);
      console.log(favorites)
      var picks = {}
      Object.values(favorites).forEach((game)=>{
        Object.values(game.teams).forEach((team)=>{
          Object.values(team.players).forEach((player)=>{
            picks[player.playerId] = true
          })
        })
      })
      setPicks(picks)
      setPicksToken(access_token)
      checkPicksFavorites(access_token)

    })
  }

  function getYesterdaysRecap() {
    //yesterdaysResults
    /*fetch(domain+'/pick/yesterday/record')
    .then(results => results.json())
    .then(data => {
      const {record} = data;
    })*/
    fetch(domain+'/pick/year/record')
    .then(results => results.json())
    .then(data => {
      const {record, avgWinOdds, percent, profit} = data["2024"];
      setYesterdaysResults(`If you put a $100 straight bet on each player we have picked in 2025, you would be up $${profit}. `)
      setYearResults(`Win rate of ${percent*100}% at +${avgWinOdds} odds on straight bets. Record: (${record.w}/${record.t})`)
    })
  }

  async function syncFavorites(e, favoritesToken) {
    await localStorage.setItem('favorites', decodeURIComponent(favoritesToken))
    if(user?.id) {
      fetch(domain+'/pick/sync', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({"appleId": user.id})}) 
    }
    getFavorites()
  }

  async function checkPicksFavorites(token) {


    fetch(domain+'/favorites', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }})
    .then(results => results.json())
    .then(data => {
      const {favorites} = data;
      setPicksFavorites(favorites)
      
    });
  }

  function picksSelected(){
    if(!picksFavorites){
      setPicksMatchFavorites(false)
      return
    }
    if(picksFavorites && !favorites){
      setPicksMatchFavorites(true)
      return
    }
    var picksPlayers = new Set(Object.values(picksFavorites).map((favTeam)=>Object.values(favTeam.teams)).map((team=>team[0].players)).map((player)=>Object.keys(player)).flat())
    var favsPlayers = new Set(Object.values(favorites).map((favTeam)=>Object.values(favTeam.teams)).map((team=>team[0].players)).map((player)=>Object.keys(player)).flat())

    if(picksPlayers.isSubsetOf(favsPlayers)){
      setPicksMatchFavorites(true)
    } else {
      setPicksMatchFavorites(false)
    }
  }

  async function getFavorites() {
    //check to see if one was sent in the url
    const queryParameters = new URLSearchParams(window.location.search)
    const favoritesToSave = queryParameters.get("favorites")
    if(favoritesToSave) {
      await localStorage.setItem('favorites', decodeURIComponent(favoritesToSave))
      queryParameters.delete('favorites')
      history.replace({
        search: queryParameters.toString(),
      })

    } 
    const favorites = await localStorage.getItem('favorites') 
    setFavoritesToken(favorites)
    if (!favorites) { 
      setFavorites({})
      setFavoritesToken("")
      return; 
    }
    fetch(domain+'/favorites', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + favorites,
      }})
    .then(results => results.json())
    .then(data => {
      const {favorites} = data;
      if(favorites) {
        setFavorites(favorites)
      } else {
        localStorage.removeItem("favorites")
      }
    }).catch(()=>{
      //localStorage.removeItem("favorites")
    })
  }

  async function addFavorite(favorite) {
    console.log(favorite)
    const favorites = await localStorage.getItem('favorites') 
    fetch(domain+'/favorites', {
      method: (favorites)?'PATCH':'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + favorites,
      },
      body: JSON.stringify(favorite)}) 
    .then(results => results.json())
    .then(data => {
      const {access_token} = data;
      console.log(access_token)
      if(access_token) {
        localStorage.setItem("favorites", access_token)
        getFavorites()
      }
    }).catch(()=>{
      //localStorage.removeItem("favorites")
    })
    if(user?.id) {
      fetch(domain+'/pick', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({"gameId": favorite.gameId, "teamId":favorite.teamId, "playerId":favorite.playerId, "appleId": user.id})}) 
      .then(results => results.json())
      .then(data => {
        console.log(data)
      })
    }
  }

  const executeScroll = (gidx) => {
    console.log('scroll', gidx, gamesRef[gidx])
    gamesRef[gidx].current.scrollIntoView()    
  }


  function loadcanvas(gidx, retry = 0) {
    // Setup the canvas element.
    if(canvasRef[gidx]?.current?.getAttribute("loaded")){
      return;
    }
    if((!canvasRef[gidx]?.current)){
      setTimeout(()=>{
        if(canvasRef[gidx].current){
          new courtSimulator(canvasRef[gidx].current)
          canvasRef[gidx].current.setAttribute("loaded", "true")
        }
      },1000)
    } else {
      new courtSimulator(canvasRef[gidx].current)
      canvasRef[gidx].current.setAttribute("loaded", "true")
    }
  };

  function checkVisible() {
    for(var gidx=0; gidx<=20; gidx++){
      var elm = gamesRef[gidx]?.current;
      var canvasElm = canvasRef[gidx]?.current;
      if(elm) {
        var rect = elm.getBoundingClientRect();
        var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
        var visible = !(rect.bottom < 0 || rect.top - viewHeight >= 0);        
        if(canvasElm){
          canvasRef[gidx]?.current?.setAttribute("inView",visible)
        }
      }
    }
  }

  async function getUser(){
    const sdkUser = await window.wvSDK.getUser()
    if(!sdkUser){
      return
    }
    setUser(sdkUser)
    if((sdkUser.isAuthed && sdkUser.isPro) || (sdkUser.platform=="Android" && sdkUser.isPro)) {
      console.log('user is pro, setting and loading canvas')
      if(!proEnabled){
        setProEnabled(true)
      }
      
    } else if(!sdkUser.isAuthed || !sdkUser.isPro){
      setProEnabled(false)
    }
    if(sdkUser.isAuthed && sdkUser.id == "LkrcHLlc4oMj9h51SyMoz8RZ18Y2") { //admin
      setAdmin(true)
    }else {
      setAdmin(false)
    }
  }

  function animateValue(element, start, end, duration) {
    let startTimestamp = null;
  
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      element.textContent = Math.floor(start - (start - end) * progress);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
  
    window.requestAnimationFrame(step);
  }
  
 

  React.useEffect( () => {
    
    getLive()
    getFavorites()
    getJWT()
    getYesterdaysRecap()
    getGames().then((gmz)=>{
      gmz.forEach((g,gidx)=>{
        loadcanvas(gidx)
      })
    })
    getUser()

    const element = document.getElementById("number");
    animateValue(element, 0,59, 60000);
    
    //watchWindow()
    window.wvSDK.on('init', (data)=>{
      alert("init complete")
    })
  }, []); 

  useInterval( () => {
    if(clock.getHours() >= 12) {
      getLive()
      getJWT()
    }
  }, 1000*10);//every 10 seconds

  useInterval(() => {
    if(clock.getHours() >= 12) {
      getGames().then((gmz)=>{
        gmz.forEach((g,gidx)=>{
          loadcanvas(gidx)
        })
      })
      getUser()
    }
  }, 1000*60); //every  minute
  useInterval(()=>{
    var date =  new Date(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }))
    setClock(date)
    checkVisible()
    picksSelected()
  }, 1000)

  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  function formaSecondsAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    seconds = seconds < 10 ? '0'+seconds : seconds;
    var strTime = hours + ':' + minutes +':' + seconds + ' ' + ampm;
    return strTime;
  }

  async function copyURL(){
    const favorites = await localStorage.getItem('favorites') 
    navigator.clipboard.writeText(webDomain + "/admin/live?favorites="+encodeURIComponent(favorites).replace(/\./g, '%2E'));
    alert("Favorites Copied")
  }

  
  function toggleBenchDisplay(e, gameId, homeOrAway) {
    if(homeOrAway == 'home'){
      if(typeof benchDisplay[gameId] === 'undefined' || !benchDisplay[gameId]) {
        benchDisplay[gameId]= true
       }  else {
        benchDisplay[gameId]= false
       }
      setBenchDisplay({...benchDisplay})
    }
    if(homeOrAway == 'away'){
      if(typeof awayBenchDisplay[gameId] === 'undefined' || !awayBenchDisplay[gameId]) {
        awayBenchDisplay[gameId]= true
       }  else {
        awayBenchDisplay[gameId]= false
       }
       setAwayBenchDisplay({...awayBenchDisplay})
    }
  }


  return (
    <div style={{ padding: "30px 5px",  overflow:"hidden", minHeight:"100vh + 20px"  }}>
      <div style={{ margin:"-30px 0px 0px 0px", background: "linear-gradient(to bottom, #d3d3d3, #555351)", marginBottom:"20px" }}>
        <div style={{height:"150px", opacity:0.5}}>
          <div style={{position:"absolute", top:85, left:0, right:0, borderBottom:"1px solid #555351", height:0}}></div>
          <div style={{position:"absolute", top:70, left:"50%", height:30, width:30, borderRadius:15, background:"#242424",  boxShadow: "rgba(255,255,255, 0.5) 3px 3px 8px 4px inset"}}></div>
          <img src={flyingPic} style={{position:"absolute", "left":0, top:25, height:"120px"}}/>
          <img src={playerPic} style={{position:"absolute", "right":0, height:"150px"}}/>
        </div>
        <div style={{ fontWeight:200, textShadow: "rgb(110 109 107) 1px 1px", letterSpacing: "0.08em", fontSize: "34px",align:"center", textAlign:"center"}}>
      
          {(clock.getHours()<12)?"Yesterday's":"Today's"} NBA Games<br />
          {(admin)?<span style={{fontSize:"10px",display:"block", margin:"0 auto", width:"100%", lineHeight:"12px"}}>Admin Mode</span>:null}
          {(clock.getHours()>=12)?
          <small style={{fontSize:"18px", letterSpacing: "0.03em"}}>Live Stats Last Updated: {lastUpdated}</small>:
          <small style={{fontSize:"18px", letterSpacing: "0.03em"}}>Todays games update in {11-clock.getHours()}:{((59-clock.getMinutes())<10)?"0":""}{59 -clock.getMinutes()}:{((59-clock.getSeconds())<10)?"0":""}{59 -clock.getSeconds()}</small>
          }
      </div>
    </div>
        <div style={{display:"none"}}>
      <span id="number">1000</span>
      <NumberFlip number={clock.getSeconds()} />
      </div> 
      <p className="title-header">
          Threesday Profit Tracker:<br/>
          <small>{(yesterdaysResults)}</small><br/>
          <small style={{fontSize:8}}>{(yearResults)}</small>
        </p>

      {(clock.getHours()>=0 && clock.getHours()<12)?
        null
      :<>
      {(games && games.length >0 && clock.getHours()>=12 && Object.keys(picks).length<=0)?
        <p className="title-header" style={{ fontSize: "20px",align:"center", textAlign:"center"}}>
          AI Models currently running. Come back before the first game to see them, or enable push notifications and we will let you know when they are ready.
        </p>
      :(games && games.length >0 && Object.keys(picks).length>0 && clock.getHours()<=24 && !picksMatchFavorites)?
      <p className="title-header">
          <span>{(user?.name)?"Hey "+user.name+"! ":null}</span>We have {(Object.keys(picks).length)} official picks for {(clock.getMonth()+1)}/{(clock.getDate())}. Tap below to see them.
            <button onClick={(event)=>{syncFavorites(event, picksToken)}} style={{padding:"7px", margin:"5px auto", border: "1px solid green", background:"green", fontSize:14, color:"white", borderRadius:5, float:"center", width:"calc(100vw - 60px)"}}>
            <span style={{fontWeight:"bold", padding:"5px"}}>View Threesday AI Picks</span>
            </button>
        </p>
      :<></>}
      
      </>}
      

      {(favorites && Object.values(favorites).length >0)?
      <>
        <Container fluid style={{padding: "0"}}>
          <div className="title-header" style={{marginBottom:"10px"}}>
            Favorites
            <Button style={{float:"right", marginTop:"-5px"}} onClick={copyURL}>Share&nbsp;<FaShareAlt /></Button>
          </div>
          <Row>          
              {(favorites && Object.values(favorites).length >0)?games.filter((game)=>(favorites[game.gameId])?game:null).map((game) => 
                <FavoriteCard executeScroll={executeScroll} gidx={games.findIndex(gamez => gamez.gameId === game.gameId)} key={'gameFavCard'+game.gameId} favorites={favorites} picksFavorites={picksFavorites} game={game} live={live}/>
                ):"No Favorites"}
          </Row>
          {((favorites && Object.values(favorites).length >0)?
            <DkLink games={games} favorites={favorites} proenabled={proEnabled}/>
          :"")}
        </Container>
      </>:<></>
      }
      <div style={{display:"none"}} sclassName="title-header">
        Games
      </div>
      
      <Container fluid style={{padding: "0"}}>
        <Row>
          <Col md="12">
          {(loadingGames && false)?
              <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
            :<></>}

            {(games && games.length >0)?games.map((game, gidx) => (
              <Card ref={gamesRef[gidx]} className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4" style={{textTransform:"uppercase",backgroundColor: "#242424", padding:"10px", margin:"-5px", borderRadius:"5px"}}>
                    <Row>
                      <Col xs="8">
                        <div style={{fontSize: (game.awayTeam.teamName.length>10)?"18px":""}}>
                          <img src={require(`assets/img/logos/${(game.awayTeam.teamTricode)}.png`)} style={{marginTop: "-6px", width:'30px', height:'30px', borderRadius:'10px'}} />&nbsp;
                          {game.awayTeam.teamName}
                          <span className="score-box" style={{fontSize:"18px", borderRadius: "5px", width: "50px", float:"right"}}>{(live && live[game.gameId] && live[game.gameId]?.awayTeamScore)?live[game.gameId]?.awayTeamScore:""}</span>
                        </div>
                        <div style={{fontSize: (game.homeTeam.teamName.length>10)?"18px":"", marginTop: "6px", borderTop: "1px solid #717171", paddingTop: "3px"}}>
                          <img src={require(`assets/img/logos/${(game.homeTeam.teamTricode)}.png`)} style={{width:"30px", height:"30px", borderRadius:"10px"}}/>&nbsp;
                          {game.homeTeam.teamName}
                          <span className="score-box" style={{fontSize:"18px", borderRadius: "5px", width: "50px", float:"right"}}>{(live && live[game.gameId] && live[game.gameId]?.homeTeamScore)?+live[game.gameId]?.homeTeamScore:""}</span>
                        </div>
                      </Col>
                      <Col style={{fontSize:"17px"}}>
                        {live[game.gameId]&&live[game.gameId].gameStatusText || game.gameStatusText}
                        <br /><small>{new Date(game.gameEt).getMonth()+1}/{new Date(game.gameEt).getDate()} {formatAMPM(new Date(game.gameTimeUTC))}</small>
                      </Col>
                    </Row>
                    </Card.Title>
                </Card.Header>
                    <Card.Body className="table-half-width table-responsive px-0" style={{overflow:"hidden",marginBottom:0, paddingBottom: 0}}>
                    <canvas 
                      style={{width:"calc(100vw - 10px)"}}
                      ispro={(proEnabled)?"true":"false"}
                      lastplay={live[game.gameId]?.lastPlay} 
                      gamestatus={live[game.gameId]?.gameStatusText} 
                      homeplayers={
                        Object.values(game.homeTeam.players).filter((player) => live[game.gameId]?.players[player.personId]?.onCourt==true).map((player)=>(player && typeof player.firstName === 'string')?"#"+player.jerseyNumber + " "+player.firstName[0]+". "+player.lastName:player.lastName).slice(0,5)
                      } 
                      awayplayers={
                        Object.values(game.awayTeam.players).filter((player) => live[game.gameId]?.players[player.personId]?.onCourt==true).map((player)=>(player && typeof player.firstName === 'string')?"#"+player.jerseyNumber + " "+player.firstName[0]+". "+player.lastName:player.lastName).slice(0,5)
                      }
                      position={canvasPlayerPosition} 
                      ref={canvasRef[gidx]} 
                      awayimage={(require(`assets/img/logos/${(game.awayTeam.teamTricode)}.png`))} 
                      homeimage={(require(`assets/img/logos/${(game.homeTeam.teamTricode)}.png`))}
                    />
                      <Table className="" style={{fontSize:"xx-small", overflowX: "hidden"}}>
                        <thead>
                          <tr className="small-header">
                            <th className="border-0" >PLYR<hr/>LINEUP STATUS</th>
                            <th className="border-0" style={{display:"none"}}>3PM/A GAME<hr />AVG sec</th>
                            <th className="border-0">3PM/A TEAM<hr />AVG sec</th>
                            <th className="border-0">AVG 3PPG<hr />GMS PLYD<hr />STARTS</th>
                            <th className="border-0">TOT<br />3PM/A<hr />3PM %</th>
                            <th className="border-0">THREESDAY<br />RANK<br />SCORE</th>
                            <th className="border-0">{(live[game.gameId])?<>LIVE<br />STATS</>:"ADD FAV"}</th>
                          </tr>
                        </thead>
                        <tbody style={{overflowX: "hidden"}}>
                        <tr style={{backgroundRepeat: "repeat-x", backgroundPosition:"0px -20px", backgroundSize:"20%", backgroundImage: "url("+require(`assets/img/logos/${(game.awayTeam.teamTricode)}.png`)+")"}}><td colSpan="1000"  className="team-spacer">{game.awayTeam.teamName}</td></tr>
                        
                          {Object.values(game.awayTeam.players).filter((player)=>player.starting).sort((a,b)=>(Object.values(b.threes.firstTeamThree).length-Object.values(a.threes.firstTeamThree).length)).map((player, id) => (
                              <PlayerList proenabled={proEnabled} key={player.personId} live={live} favorites={favorites} game={game} player={player} id={id} picks={picks}  addFavorite={addFavorite}/>
                            ))}
                            {(!live[game.gameId])?
                            Object.values(game.awayTeam.players).map((player, id) => (
                            (player.rosterStatus != "Active" && Object.values(player.threes.firstTeamThree).length>1)?
                              <PlayerList proenabled={proEnabled} key={'inactive'+player.personId} live={live} favorites={favorites} game={game} player={player} id={id} picks={picks} addFavorite={addFavorite}/>
                            :<></>
                            )):<></>}
                            <td className="text-white" colSpan="10" style={{textAlign:"center"}}>
                              <button  className="btn btn-primary" onClick={(event) => toggleBenchDisplay(event, game.gameId, 'away')} game={game.gameId}>{awayBenchDisplay[game.gameId]}{awayBenchDisplay[game.gameId]?"Hide":"Show"} Bench</button>
                              </td>
                            {(awayBenchDisplay[game.gameId])?Object.values(game.awayTeam.players).map((player, id) => (
                            (!player.starting && player.rosterStatus == "Active")?
                              <PlayerList proenabled={proEnabled} key={'bench'+player.personId}live={live} favorites={favorites} game={game} player={player} id={id} picks={picks} addFavorite={addFavorite}/>
                            :<></>
                            )):<></>}
                            
                        <tr style={{backgroundRepeat: "repeat-x", backgroundPosition:"0px -20px", backgroundSize:"20%", backgroundImage: "url("+require(`assets/img/logos/${(game.homeTeam.teamTricode)}.png`)+")"}}><td colSpan="1000" className="team-spacer">{game.homeTeam.teamName}</td></tr>
                            {Object.values(game.homeTeam.players).filter((player)=>player.starting).sort((a,b)=>(Object.values(b.threes.firstTeamThree).length-Object.values(a.threes.firstTeamThree).length)).map((player, id) => (
                              <PlayerList proenabled={proEnabled} key={player.personId} live={live} favorites={favorites} game={game} player={player} id={id} picks={picks} addFavorite={addFavorite}/>
                            ))}
                            {(!live[game.gameId])?
                            Object.values(game.homeTeam.players).map((player, id) => (
                            (player.rosterStatus != "Active" && Object.values(player.threes.firstTeamThree).length>1)?
                              <PlayerList proenabled={proEnabled} key={'inactive'+player.personId} live={live} favorites={favorites} game={game} player={player} id={id} picks={picks}  addFavorite={addFavorite}/>
                            :<></>
                            )):<></>}
                            <td className="text-white" colSpan="10" style={{textAlign:"center"}}>
                              <button className="btn btn-primary" onClick={(event) => toggleBenchDisplay(event, game.gameId, 'home')} game={game.gameId}>{benchDisplay[game.gameId]}{benchDisplay[game.gameId]?"Hide":"Show"} Bench</button>
                            </td>
                            {(benchDisplay[game.gameId])?Object.values(game.homeTeam.players).map((player, id) => (
                            (!player.starting && player.rosterStatus == "Active")?
                              <PlayerList proenabled={proEnabled} key={'bench'+player.personId} live={live} favorites={favorites} game={game} player={player} id={id} picks={picks} addFavorite={addFavorite}/>
                            :<></>
                            )):<></>}
                            <tr>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>              
              </Card>
              )):"No Games Today"}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LiveList;
