import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

function policy(){

  const [email, setEmail] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleDelete = async () => {
    try {
      const response = await fetch('https://api.threes.day/user', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        alert('User account deleted successfully.');
        setEmail('');
      } else {
        alert('Failed to delete user account.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    } finally {
      setShowModal(false);
    }
  };
    return (
        <div data-custom-class="body">
            <div className="container mt-5">
      <h1>Delete User Account</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setShowModal(true);
        }}
      >
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email Address
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-danger">
          Delete Account
        </button>
      </form>

      {/* Confirmation Modal */}
      {showModal && (
        <div className="modal show d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Deletion</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete the account associated with {email}?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
                <button type="button" className="btn btn-danger" onClick={handleDelete}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
          <div>
            <strong>
              <span style={{ fontSize: '26px' }}>
                <span data-custom-class="title">PRIVACY POLICY</span>
              </span>
            </strong>
          </div>
          <div>
            <br />
          </div>
          <div>
            <span style={{ color: 'rgb(127, 127, 127)' }}>
              <strong>
                <span style={{ fontSize: '15px' }}>
                  <span data-custom-class="subtitle">Last updated January 21, 2025</span>
                </span>
              </strong>
            </span>
          </div>
          <div>
            <br />
          </div>
          <div>
            <span style={{ color: 'rgb(127, 127, 127)' }}>
              <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  This privacy notice for <strong>Hudson AI Platforms LLC</strong> ("we," "us," or
                  "our"), describes how and why we collect, store, use, and/or share your information
                  when you use our services ("Services"), such as the Threesday app. We currently
                  collect email information for contact purposes. However, this may not be limited to
                  that in the future.
                </span>
              </span>
            </span>
          </div>
          <div>
            <span style={{ color: 'rgb(127, 127, 127)' }}>
              <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  We strive to maintain the accuracy of the data presented in the Threesday app. However,
                  there are no guarantees regarding its accuracy or completeness. Hudson AI Platforms LLC
                  and the Threesday app are not responsible for any actions taken based on the information
                  presented in the app.
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li>
              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                <span data-custom-class="body_text">
                  Visit our website at <a href="https://threes.day">threes.day</a>, or any website of
                  ours that links to this privacy notice.
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                <span data-custom-class="body_text">
                  Download and use our mobile application (Threesday), or any other application of ours
                  that links to this privacy notice.
                </span>
              </span>
            </li>
            <li>
              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                <span data-custom-class="body_text">
                  Engage with us in other related ways, including any sales, marketing, or events.
                </span>
              </span>
            </li>
          </ul>
          <div>
            <span data-custom-class="body_text">
              Questions or concerns? Reading this privacy notice will help you understand your privacy
              rights and choices. If you do not agree with our policies and practices, please do not use
              our Services. If you still have any questions or concerns, please contact us at{' '}
              <a href="mailto:info@threes.day">info@threes.day</a>.
            </span>
          </div>
    
          {/* Updated clauses start here */}
    
          <div>
            <h2>Information We Collect</h2>
            <p>We may collect the following types of information:</p>
            <ul>
              <li>Personal identification information (Name, email address, phone number, etc.)</li>
              <li>Device information (IP address, browser type, operating system)</li>
              <li>Usage data (preferences, interactions with our services)</li>
              <li>Location data, where permitted by law</li>
            </ul>
          </div>
    
          <div>
            <h2>How We Use Your Information</h2>
            <p>We may use the information we collect to:</p>
            <ul>
              <li>Provide and improve our services</li>
              <li>Personalize user experience</li>
              <li>Send administrative information or promotional materials</li>
              <li>Comply with legal obligations</li>
              <li>Prevent fraudulent activities</li>
            </ul>
          </div>
    
          <div>
            <h2>Sharing Your Information</h2>
            <p>We may share your information with:</p>
            <ul>
              <li>Service providers who assist us in delivering our services</li>
              <li>Business partners for joint offerings</li>
              <li>Authorities, where required by law</li>
              <li>Other third parties with your consent</li>
            </ul>
          </div>
    
          <div>
            <h2>Your Privacy Rights</h2>
            <p>Depending on your location, you may have the following rights:</p>
            <ul>
              <li>The right to access and review your information</li>
              <li>The right to correct or delete your information</li>
              <li>The right to object to processing</li>
              <li>The right to data portability</li>
              <li>The right to withdraw consent at any time</li>
            </ul>
          </div>
    
          <div>
            <h2>Data Retention</h2>
            <p>We will retain your information for as long as necessary to fulfill the purposes outlined in this policy unless otherwise required by law.</p>
          </div>
    
          <div>
            <h2>Children's Privacy</h2>
            <p>Our services are not directed to individuals under the age of 13, and we do not knowingly collect data from them.</p>
          </div>
    
          <div>
            <h2>Security Measures</h2>
            <p>We implement technical, administrative, and physical security measures to protect your data from unauthorized access or disclosure.</p>
          </div>
    
          <div>
            <h2>Updates to This Policy</h2>
            <p>We may update this policy periodically. Changes will be reflected by the "Last updated" date above.</p>
          </div>
    
          <div>
            <h2>Contact Us</h2>
            <p>If you have questions or concerns about this policy, please contact us at <a href="mailto:info@threes.day">info@threes.day</a>.</p>
          </div>
        </div>
      );
}
export default policy